const styles = {
  container: 'flex flex-col mb-[2.5rem]',
  content: 'flex flex-col gap-y-5',

  head: {
    container: '',
  },

  body: {
    container: 'flex flex-col',

    figure:
      // 'rounded-xl overflow-hidden relative bg-gray-900 aspect-video 3xl:!aspect-auto',
      // 'rounded-xl overflow-hidden relative bg-gray-900 h-full grow max-h-[30rem]',
      'rounded-xl overflow-hidden relative bg-gray-900 h-full grow max-h-[30rem]',

    image: 'object-cover duration-500 ease-in scale-105',
  },

  foot: {
    container: 'relative',

    list: 'flex flex-col gap-y-4 sm:grid grid-cols-2 lg:grid-cols-4 gap-x-6',
  },
};

export default styles;
