'use client';

import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

// import { toClassName } from 'helpers';

import { handleUseStrapiImageUrl } from 'helpers/strapi';

import { styles } from '.';

type TProps = {
  index: number;
  location: any;
  lang: string;
  setActiveIndex: any;
};

const HomeLandingListItem = (props: TProps) => {
  const { index, lang, location, setActiveIndex } = props;

  return (
    <li>
      <Link
        href={`/${lang}/${location.slug}`}
        onMouseEnter={() => setActiveIndex(index)}
        onMouseLeave={() => setActiveIndex(null)}
      >
        <div className={styles.container}>
          <Image
            src={handleUseStrapiImageUrl(
              `/images/texture-${location.slug}.png`,
            )}
            fill
            alt="texture"
            className="object-cover"
          />
          <h2 className={styles.heading}>{location.translations.title}</h2>
          <p className={styles.paragraph}>{location.translations.subtitle}</p>
        </div>
      </Link>
    </li>
  );
};

export default HomeLandingListItem;
