'use client';

import { Suspense, useState } from 'react';

import Image from 'next/image';

import { toClassName } from 'helpers';

import { handleUseStrapiImageUrl } from 'helpers/strapi';

import HomeLandingListItem from '../components/ListItem/HomeLandingListItem';
import styles from '../Landing/HomeLandingPage.styles';

type TProps = {
  lang: string;
  //
  general: any;
  locations: any;
};

type TLocation = any;

const HomeClientView = (props: TProps) => {
  const { lang, general, locations } = props;

  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <>
      <div className={styles.head.container}>
        <Image
          src="/images/logo-big.svg"
          width={400}
          height={100}
          alt="logo-big"
          className="w-full"
          priority
        />
      </div>
      <div className={styles.body.container}>
        <div className={styles.body.figure}>
          <Suspense>
            <video
              autoPlay
              loop
              muted
              playsInline
              src={handleUseStrapiImageUrl(
                general?.attributes?.welcomeShowreel?.data?.attributes?.url,
              )}
              // className="!object-cover !h-full"
            />
          </Suspense>
          {locations.map((location: TLocation, index: number) => {
            return (
              <Image
                key={location.id}
                src={handleUseStrapiImageUrl(
                  location.general.coverBackgroundImage.data.attributes.url,
                )}
                fill
                alt="landing"
                className={toClassName(
                  styles.body.image,
                  activeIndex === index ? 'opacity-100' : 'opacity-0',
                )}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.foot.container}>
        <ul className={styles.foot.list}>
          {locations.map((location: TLocation, index: number) => (
            <HomeLandingListItem
              key={location.id}
              index={index}
              location={location}
              lang={lang}
              setActiveIndex={setActiveIndex}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default HomeClientView;
